import {
  STATUS_DRAFT,
  STATUS_PUBLISHED,
  STATUS_SCHEDULED,
  STATUS_UNPUBLISHED,
} from '@/storychief/constants/Constants';
import { CHANNEL_TWITTER } from '@/destinations/constants/Constants';
import getPostsetAttachmentsType from '@/postsets/utils/getPostsetAttachmentsType';
import PostFeedbackMessages from '@/posts/utils/PostFeedbackMessages';
import { formatDate } from '@/date';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';

export default class PostsetFeedbackMessages {
  constructor(postset, newAttachments = []) {
    this.feedbackMessages = [];
    this.postset = postset;
    this.newAttachments = newAttachments;
  }

  getMessages() {
    return new Promise((resolve) => {
      if (this.postset) {
        const isAnyDestinationEnabled = this.postset.posts.some((post) => !post.deleted_at);

        if (!this.postset.can.update_actions) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'You do not have the necessary permissions to perform this action. Please ask your administrator for assistance / approval.',
            type: 'update-actions',
          });
        }

        if (!isAnyDestinationEnabled) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'At least 1 channel is required for publishing',
            type: 'destination-none',
          });
        }

        const twitterDestinationsEnabled = this.postset.posts.filter(
          (post) => post.destination.channel_type === CHANNEL_TWITTER,
        );

        const now = getDateObjectWithTimezone();
        const scheduledAtCalculated = getDateObjectWithTimezone(
          this.postset.scheduled_at_calculated,
        );

        if (scheduledAtCalculated < now) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'Posts are not allowed to be scheduled in the past.',
            type: 'posts-scheduled-at',
          });
        }

        if (twitterDestinationsEnabled.length > 1) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'Multiple X (Twitter) posts are not allowed.',
            type: 'twitter-multiple-posts',
          });
        }

        if (!this.postset.message && !this.postset.attachments.length) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'Write a message or add an attachment before publishing',
            type: 'message-and-attachment-empty',
          });
        }

        if (this.newAttachments.length > 0) {
          this.feedbackMessages.push({
            level: 'error',
            text: 'Attachment uploading is in progress. Please wait until the uploading finishes.',
            type: 'attachment-uploading',
          });
        }

        const attachmentType = getPostsetAttachmentsType(this.postset.attachments);
        switch (attachmentType) {
          case 'Story': {
            if (
              this.postset.attachments.length &&
              this.postset.attachments[0].attachable.deleted_at
            ) {
              this.feedbackMessages.push({
                level: 'error',
                text: 'The attached article is deleted, please choose another attachment',
                type: 'story-deleted',
              });
            } else if (this.postset.attachments[0].attachable.status === STATUS_DRAFT) {
              this.feedbackMessages.push({
                level: 'error',
                text: 'The attached article is still in draft and needs to be published or scheduled first',
                type: 'story-draft',
              });
            } else if (this.postset.attachments[0].attachable.status === STATUS_SCHEDULED) {
              const storyScheduledAtDate = getDateObjectWithTimezone(
                this.postset.attachments[0].attachable.scheduled_at,
              );

              const outDatedPosts = this.postset.posts.find((post) => {
                // If scheduled_at is not present we will publish now,
                const postScheduledAtDate = post.scheduled_at
                  ? getDateObjectWithTimezone(post.scheduled_at)
                  : getDateObjectWithTimezone();

                return (
                  postScheduledAtDate < storyScheduledAtDate &&
                  post.status !== STATUS_PUBLISHED &&
                  post.status !== STATUS_UNPUBLISHED &&
                  !post.deleted_at
                );
              });

              if (outDatedPosts) {
                this.feedbackMessages.push({
                  level: 'error',
                  text: `The attached article will be published on ${formatDate(
                    this.postset.attachments[0].attachable.scheduled_at,
                    'monthLong',
                  )}. Change the schedule dates of your posts`,
                  type: 'story-outdated-posts',
                });
              }
            }
            break;
          }
          case 'Link': {
            break;
          }
          case 'Image': {
            if (this.postset.attachments.length > 1) {
              const attachmentIds = this.postset.attachments.map(({ attachable }) => attachable.id);
              const checkPostAllExcluded = ({ excluded_attachables }) =>
                excluded_attachables &&
                attachmentIds.every((id) => excluded_attachables.includes(id));
              const hasAllExcluded = this.postset.posts.some(checkPostAllExcluded);
              if (hasAllExcluded) {
                this.feedbackMessages.push({
                  level: 'error',
                  text: 'Select at least 1 image for each channel',
                  type: 'image-all-excluded',
                });
              }
            }
            break;
          }
          case 'Video': {
            break;
          }
          default:
            break;
        }

        if (this.postset && this.postset.posts) {
          const combinedPosts = [...this.postset.posts, ...this.postset.referral_outreaches];
          const draftedCombinedPosts = combinedPosts.filter(
            (_post) => _post.status === STATUS_DRAFT,
          );
          const postsFeedbackMessages = [];
          Promise.all(
            draftedCombinedPosts.map((_post) => {
              const postFeedbackMessages = new PostFeedbackMessages(_post);
              return postFeedbackMessages.getMessages().then(() => {
                postsFeedbackMessages.push(...postFeedbackMessages.feedbackMessages);
              });
            }),
          ).then(() => {
            // summarize
            const postsFeedbackMessagesSummarized = postsFeedbackMessages.reduce((acc, curr) => {
              const indexSameText = acc.findIndex(
                (item) => item.text === curr.text && item.channel_type === curr.channel_type,
              );
              if (indexSameText === -1) {
                const newFeedbackMessage = curr;
                newFeedbackMessage.objects = [curr.object];
                delete newFeedbackMessage.object;
                acc.push(newFeedbackMessage);
              } else {
                acc[indexSameText].objects.push(curr.object);
              }
              return acc;
            }, []);
            this.feedbackMessages.push(...postsFeedbackMessagesSummarized);
            resolve();
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }
}
