function pushHistoryAddOverlayPostset(history, postsetIds, initialCampaigns = []) {
  const searchParams = new URLSearchParams(history.location.search);
  const postsetEditPaths = postsetIds.map((postsetId) => `postsets/${postsetId}/edit`).join();

  if (searchParams.get('overlay')) {
    const _searchParamsOverlay = searchParams.get('overlay').split(',');
    _searchParamsOverlay.unshift(postsetEditPaths);
    searchParams.set('overlay', _searchParamsOverlay.join());
  } else {
    searchParams.set('overlay', postsetEditPaths);
  }

  if (initialCampaigns && initialCampaigns.length > 0) {
    // Initial campaigns for a new postset can be defined using URL parameters (eg. on the calendar
    // page when a campaign is selected using the filters).
    searchParams.set('initial-campaigns', initialCampaigns.join());
  }

  history.push({
    pathname: history.location.pathname,
    search: searchParams.toString(),
  });
}

export default pushHistoryAddOverlayPostset;
