function pushHistoryRemoveOverlayPostset(history, postsetId) {
  const searchParams = new URLSearchParams(history.location.search);
  if (searchParams.get('overlay')) {
    let _searchParamsOverlay = searchParams.get('overlay').split(',');
    _searchParamsOverlay = _searchParamsOverlay.filter(
      (paramOverlay) => !paramOverlay.startsWith(`postsets/${postsetId}/`),
    );
    _searchParamsOverlay = _searchParamsOverlay.join();
    if (_searchParamsOverlay.length) {
      searchParams.set('overlay', _searchParamsOverlay);
    } else {
      searchParams.delete('overlay');
    }

    searchParams.delete('initial-campaigns');

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  }
}

export default pushHistoryRemoveOverlayPostset;
