export const MAX_SELECTED_MEDIA = 10;

export const EVENTS = {
  updateList: 'update-postsets-list',
  create: 'create-postset',
};

export const ATTACHMENT_TYPE = {
  image: 'Image',
  link: 'Link',
  story: 'Story',
  document: 'Document',
  video: 'Video',
  media: 'Media',
} as const;

export type AttachmentType = (typeof ATTACHMENT_TYPE)[keyof typeof ATTACHMENT_TYPE];
