import { useReactiveVar } from '@apollo/client';
import { newPostsetAttachmentsVar } from '@/graphql/cache';

export default function useNewPostsetAttachments() {
  function setNewAttachments(attachments) {
    newPostsetAttachmentsVar(attachments);
  }

  return [useReactiveVar(newPostsetAttachmentsVar), setNewAttachments];
}
