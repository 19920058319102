import { useReactiveVar } from '@apollo/client';
import { newPostAttachmentsVar } from '@/graphql/cache';

export default function useNewPostAttachments() {
  function setNewPostAttachments(attachments) {
    const existingAttachments = newPostAttachmentsVar();
    let newAttachments = [...existingAttachments];

    attachments.forEach(({ postIds, attachableId, attachable }) => {
      const existingIndex = newAttachments.findIndex(
        (a) =>
          a.postIds.toString() === postIds.toString() &&
          a.attachable.data.sourceId === attachable?.data?.sourceId,
      );

      if (attachable === null) {
        newAttachments = newAttachments.filter((a) => a.postIds.toString() !== postIds.toString());
      } else if (existingIndex > -1) {
        newAttachments[existingIndex] = {
          postIds,
          attachableId,
          attachable,
        };
      } else {
        newAttachments.push({
          postIds,
          attachableId,
          attachable,
        });
      }
    });

    return newPostAttachmentsVar(newAttachments);
  }

  return [useReactiveVar(newPostAttachmentsVar), setNewPostAttachments];
}
