import { gql, useMutation } from '@apollo/client';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import pushHistoryRemoveOverlayPostset from '@/postsets/utils/pushHistoryRemoveOverlayPostset';
import pushHistoryAddOverlayPostset from '@/postsets/utils/pushHistoryAddOverlayPostset';
import PostsetFeedbackMessages from '@/postsets/utils/PostsetFeedbackMessages';
import PostsetPostImageEditContext from '@/postsets/context/PostsetPostImageEdit';
import useNewPostsetAttachments from '@/new-attachments/hooks/useNewPostsetAttachments';
import useNewPostAttachments from '@/new-attachments/hooks/useNewPostAttachments';
import { useEmitEvent } from '@/storychief/hooks';
import { PostsetMiddleware, ErrorsMiddleware } from '@/graphql/middlewares';
import { EVENTS as POSTSET_EVENTS } from '@/postsets/constants/Constants';
import { getISODate } from '@/date';

const CREATE_POSTSET_MUTATION = gql`
  mutation CreatePostset($input: CreatePostsetInput) {
    createPostset(input: $input) {
      __typename
      id
      is_private
      campaigns {
        value: id
        label: name
        id
        is_private
        name
        slug
        color
        edit_url
      }
      lock(lockIfAccessible: true) {
        id
        token
        locked_until
        user_id
      }
    }
  }
`;

const DELETE_POSTSET_MUTATION = gql`
  mutation DeletePostset($id: ID!) {
    deletePostset(id: $id) {
      __typename
      id
      deleted_at
    }
  }
`;

/**
 * @param {boolean} pushHistory Allows you to specify if you want to push the history or not.
 * @returns {Array}
 */
export function useCreatePostsetMutation(pushHistory = true) {
  const history = useHistory();
  const emitCreateEvent = useEmitEvent(POSTSET_EVENTS.create);

  return useMutation(CREATE_POSTSET_MUTATION, {
    onCompleted: async ({ createPostset }) => {
      if (pushHistory) {
        pushHistoryAddOverlayPostset(
          history,
          [createPostset.id],
          createPostset.campaigns.map(({ id }) => id),
        );
      }

      emitCreateEvent(); // Notify PostsetsList to refetch
    },
  });
}

export function useDeletePostsetMutation(postsetId) {
  const history = useHistory();
  const optimisticResponse = {
    deletePostset: {
      __typename: 'Postset',
      id: postsetId,
      deleted_at: getISODate(),
    },
  };

  return useMutation(DELETE_POSTSET_MUTATION, {
    optimisticResponse,
    variables: {
      id: postsetId,
    },
    context: { middlewares: [new PostsetMiddleware(postsetId), new ErrorsMiddleware()] },
    update() {
      pushHistoryRemoveOverlayPostset(history, postsetId);
    },
  });
}

export function usePostsetFeedbackMessages(postset) {
  // Hooks
  const [newPostsetAttachments] = useNewPostsetAttachments();
  const [newPostAttachments] = useNewPostAttachments();

  // States
  const [postsetFeedbackMessages, setPostsetFeedbackMessages] = useState([]);

  // Variables
  const postsetId = postset ? postset.id : null;
  const postsetPosts = postset ? postset.posts : null;
  const postsetAttachments = postset ? postset.attachments : null;
  const postsetTailoredStory = postset ? postset.tailored_story : null;
  const referralOutreaches = postset ? postset.referral_outreaches : null;
  useEffect(() => {
    let ignore = false;
    const feedbackMessages = new PostsetFeedbackMessages(postset, [
      ...newPostsetAttachments,
      ...newPostAttachments,
    ]);

    feedbackMessages.getMessages().then(
      () => {
        if (!ignore) {
          setPostsetFeedbackMessages(feedbackMessages.feedbackMessages);
        }
      },
      (err) => {
        console.error(err);
      },
    );

    return () => {
      // Ignore response from previous in-flight promises
      ignore = true;
    };
  }, [
    postsetId,
    postsetPosts,
    postsetAttachments,
    postsetTailoredStory,
    newPostsetAttachments,
    newPostAttachments,
    referralOutreaches,
  ]);
  return postsetFeedbackMessages;
}

/**
 * @returns {{onAutoCrop: Function, onCropPostImages: Function, onEditImages: Function, onReplaceImages: Function, onRevert: Function}}
 */
export function usePostsetPostImageEdit() {
  const context = useContext(PostsetPostImageEditContext);

  if (context === undefined) {
    throw new Error('usePostsetPostImageEdit must be used within a PostsetPostImageEdit.Provider');
  }

  return context;
}
